'use client';
import cn from 'classnames';
import { useLocale } from 'next-intl';
import { CSSProperties, useEffect, useState } from 'react';
import {
  IoIosClose,
  IoIosCloseCircle,
  IoIosCloseCircleOutline,
} from 'react-icons/io';

import styles from './AdPopup.module.scss';

import { Link, usePathname } from '@lib/navigation';
import { fetchApi } from '@utils/api/client';
import { WRPopup } from '@utils/api/server';
import { useUserStore } from '@utils/user';
import { useBreakpoints } from '@hooks/useBreakpoints';

let timeout: any = null;

function AdPopup() {
  const [isOpen, setIsOpen] = useState(false);
  const [popup, setPopup] = useState<WRPopup | undefined>(undefined);
  const locale = useLocale();
  const isPremium = useUserStore((state) => state.isPremium);
  const isUserReady = useUserStore((state) => state.isReady);
  const pathname = usePathname();
  const { isBreakpointDown } = useBreakpoints();

  const closeSizes = {
    small: '40px',
    medium: '50px',
    large: '60px',
  };

  const wordpressLocale = (locale === 'ua') ? 'uk' : locale;

  const init = async () => {
    const res = await fetchApi<WRPopup>(`/popup/${wordpressLocale}`, {
      noAuth: true,
    });

    if (res.data) {
      setPopup(res.data);
    }
  };

  // Function moved here because of the hoisting issue
  const open = () => {
    if (!popup) {
      return;
    }

    if (isPremium && popup.blockForPremium) {
      setPopup(undefined);
      return;
    }

    if (sessionStorage.getItem(popup.id)) {
      setPopup(undefined);
      return;
    }

    const location =
      locale !== 'en'
        ? window.location.pathname.split(locale)[1]
        : window.location.pathname;

    if (popup.bannedUrls.includes(location)) {
      return;
    }

    document.body.classList.add('popup');
    setIsOpen(true);

    sessionStorage.setItem(popup.id, 'true');
  };

  useEffect(() => {
    if (!popup || timeout || !isUserReady) {
      return;
    }

    if (popup.capping !== 0 && popup.waitForRedirect) {
      timeout = setTimeout(() => {
        open();
      }, popup.capping * 1000);
    }
  }, [pathname]);

  useEffect(() => {
    if (!isUserReady) {
      return;
    }
    init();
  }, [isUserReady]);

  useEffect(() => {
    if (!popup || timeout || !isUserReady) {
      return;
    }

    // > Open popup on focus
    if (popup.openOnRefocus) {
      window.onfocus = () => {
        timeout = setTimeout(() => {
          open();
        }, 2000);
      };
    }

    if (popup.capping !== 0) {
      const time = popup.capping * 1000;

      // See useEffect above for the redirect version.
      if (!popup.waitForRedirect) {
        // > Open popup after a delay
        timeout = setTimeout(() => {
          open();
        }, time);
      }
      return () => {
        clearTimeout(timeout);
      };
    }

    open();
  }, [popup]);

  if (!isOpen || !popup || isPremium) {
    return <></>;
  }

  const close = (e) => {
    e.stopPropagation();

    document.body.classList.remove('popup');

    setIsOpen(false);
    clearTimeout(timeout);

    setPopup(undefined);
  };

  const handleClick = () => {
    // @ts-ignore
    window.dataLayer = window.dataLayer || [];
    // @ts-ignore
    window.dataLayer.push({
      event: 'popupClick',
      id: popup.id,
      campaign: popup.campaignName,
      action: 'click',
    });
  };

  const popupStyle: CSSProperties = {
    backgroundColor: popup.isBackground ? popup.backgroundColor : 'transparent',
    padding: popup.isBackground ? `${popup.backgroundPadding}px` : 0,
    borderRadius: `${popup.borderRadius}px`,
  };

  const popupContent = () => {
    switch (popup?.popupType) {
      case 'image': {
        const imgSrc =
          process.env.NODE_ENV === 'development'
            ? 'https://images.unsplash.com/photo-1701743806988-1296537d33ca?q=80&w=2832&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
            : isBreakpointDown('lg')
              ? popup.imgSrcMobile
              : popup.imgSrcDesktop;
        // eslint-disable-next-line @next/next/no-img-element
        return <img src={imgSrc} alt="popup" />;
      }
      case 'text': {
        return (
          <>
            <h2>{popup.title}</h2>
            <p>{popup.text}</p>
          </>
        );
      }
    }
  };

  const closeBtn = {
    close: <IoIosClose />,
    'close-circle': <IoIosCloseCircle />,
    'close-circle-outline': <IoIosCloseCircleOutline />,
  };

  return (
    <div className={styles.wrapper} onClick={close}>
      <div
        style={popupStyle}
        className={cn(styles.popup, {
          [styles.img]: popup.popupType === 'image',
        })}
        onClick={handleClick}
      >
        <div
          style={{
            color: popup.closeColor,
            fontSize: `${closeSizes[popup.closeSize]}`,
          }}
          className={styles.close}
          onClick={close}
        >
          {closeBtn[popup.closeButton]}
        </div>
        <Link
          href={popup.url}
          target={popup.openInNewTab ? '_blank' : undefined}
        >
          {popupContent()}
        </Link>
      </div>
    </div>
  );
}

export default AdPopup;
